import React, { useState, useEffect } from 'react';
import Uploader from './components/Uploader';
import Gallery from './components/Gallery';
import { IImage } from './types/Image';
import Amplify, { Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import './App.css';

// React Notifications
import 'react-notifications/lib/notifications.css'
import {NotificationContainer} from 'react-notifications'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NavigationBar } from './components/NavigationBar';
import { Footer } from './components/Footer';


Amplify.configure(awsconfig);

function App(this: any) {
  const [inputImages, setInputImages] = useState<IImage[]>([])
  const [selectedImages, setSelectedImages] = useState<IImage[]>([])

  useEffect(() => {
    Storage.list('InputImages')
      .then(result => {
        setInputImages(result)
      })
      .catch(err => console.log("error listing photos", err));
    //get data and call setImages with the respose
    //fetch('api-route').then(response => setImages(response.json()))
  }, [])

  const imageUploaded = (filename: string) => {
    const image = { key: filename }
    setInputImages([...inputImages, image])
  }

  return (
    <>
      <React.Fragment>
        <Router>
          <NavigationBar />
        </Router>
      </React.Fragment>
      <Uploader uploaded={imageUploaded} />
      <NotificationContainer></NotificationContainer>
      <h3>My Images</h3>

      <Gallery images={inputImages} />

      {/* <div className='gallery-block'>
        <button onClick={processImages}>Process selected images</button>
      </div> */}

      {/* 
      <input type="file" onChange={this.handleChange}/>
      <img src={this.state.fileUrl}/>
      <button onClick= {this.saveFile}>Save File</button> */}

      <React.Fragment>
        <Router>
          < Footer/>
        </Router>
      </React.Fragment>
    </>
  );
}

export default withAuthenticator(App, true);
