import React from "react";
import styled from "styled-components"

const Style = styled.footer`
    text-align: left;
    padding: 10px 0;
    margin: 20px
`

const FooterDesc = styled.p`
    color: #888
`

export const Footer = () => (
  <Style>
      <img
          src="Abova-logo.png"
          className="d-inline-block align-left"
          width="140"
          height="37"
          alt="Abova Inc logo"
      />
      <FooterDesc> &copy; 2020 All Rights Reserved. </FooterDesc>
  </Style>
)