import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {Storage} from 'aws-amplify';

import { NotificationManager } from 'react-notifications'

Storage.configure({
    level: 'public',
  });

type UploaderProps = {
    uploaded(filename:string):void;
}

function Uploader(props:UploaderProps) {
    const saveFile = (files: { name: string; }[]) => {
        if (files.length >0){
            console.log("accepted files: ", files);
            files.forEach((file: { name: string; }) => {
                const filename = "InputImages/"+Date.now() + "_" + file.name
                Storage.put(filename, file)
                .then(()=>{
                    console.log ("file saved.", filename);
                    props.uploaded(filename);
                    NotificationManager.info('Image uploaded successfully!')
                })
                .catch((err: any) =>{
                    console.log("Error", err);
                })
            });
        }
        else {
            console.log("No files accepted.");
        }
    }

    return (
        <>
            <Dropzone accept = {'image/jpeg, image/png'}  onDrop = {acceptedFiles =>  saveFile(acceptedFiles)} >
            {({getRootProps, getInputProps}) => (
                <section>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>Drag and drop files here, or click to select files</p>
                </div>
                </section>
            )}
            </Dropzone>
        </>
    )
}

Uploader.propTypes = {

}

export default Uploader

