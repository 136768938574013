    import React, {useState, useEffect} from 'react'
    import {IImage} from '../types/Image'
    import { Storage } from 'aws-amplify';

    type ImageProps = {
        image: IImage
    }

    function Image({image}: ImageProps) {
        const [url, setUrl] = useState('')
        useEffect(() => {
            Storage.get(image.key)
                .then(result => {
                    console.log('result:', result);
                    setUrl(result.toString());
                    console.log('image:', image)
                })
                .catch(err => console.log("error listing photos", err));

            //get data and call setImages with the respose
            //fetch('api-route').then(response => setImages(response.json()))
        }, [])

        // const [Image, setImage] = useState<IImage>()

        return (
            <>
                <img src={url} />
                <div className="desc">
                    <input type="checkbox" />
                    {image.key.substring(26)}
                </div>
            </>
        )
    }

    export default Image

