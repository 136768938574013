import React from 'react';
import { Nav, Navbar, Form, FormControl } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
  .navbar { background-color: #ffffff; }
  a, .navbar-nav, .navbar-light .nav-link {
    color: #eb9806;
    &:hover { color: black; }
  }
  .navbar-brand {
    font-size: 1.4em;
    color: #9FFFCB;
    &:hover { color: white; }
  }
  .form-center {
    position: absolute !important;
    left: 25%;
    right: 25%;
  }
`;

export const NavigationBar = () => (
  <Styles>
    <Navbar expand="lg">
      <Navbar.Brand href="/">
          <img
            src="AT-logo@2x.png"
            className="d-inline-block align-left"
            width="230"
            height="60"
            alt="Abova Inc logo"
          />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Form className="form-center">
        <FormControl type="text" placeholder="Search" className="" />
      </Form>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item><Nav.Link href="http://www.abovatechnologies.com/">Home</Nav.Link></Nav.Item> 
          <Nav.Item><Nav.Link href="http://www.abovatechnologies.com/about">About</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="http://www.abovatechnologies.com/resources">Resources</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link href="http://www.abovatechnologies.com/contact">Contact</Nav.Link></Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles>
)