import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import { IImage } from '../types/Image';
import { Storage } from 'aws-amplify';

Storage.configure({
    level: 'protected',
});

type GalleryProps = {
    images:IImage[]
}

function Gallery({images}:GalleryProps) {

    return (
        <>
            <div className="gallery-block">
                {images.map((image) =>
                <div className="gallery">
                    <Image image={image} />
                </div>
                )}
            </div>
        </>
    )
}

Gallery.propTypes = {

}

export default Gallery

