/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:2aae13ca-6099-4dbe-9057-adb82b710a64",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_KVZdbfSjm",
    "aws_user_pools_web_client_id": "3pu0t4fkaee3djt1pq4caiv1ni",
    "oauth": {},
    "aws_user_files_s3_bucket": "niccode58690cd82dfd4dafb6f11ccef1e6c80c84018-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
